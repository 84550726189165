.faq-container {
    background       : #b98a70;
    display          : flex;
    flex-direction   : column;
    height           : auto;
    -webkit-box-align: center;
    align-items      : center;
    padding-bottom: 50px;

    > span:nth-child(1) {
        text-align  : center;
        margin-left : 15px;
        margin-right: 15px;
        font-weight : bold;
        margin-top  : 99px;
    }

    @media (max-width: 768px) {
        > span:nth-child(1) {
            font-size: 30px;
        }
    }

    > span:nth-child(2) {
        width     : 740px;
        text-align: center;
        opacity   : 0.7;
    }

    @media (max-width: 768px) {
        > span:nth-child(2) {
            font-size : 16px;
            margin-top: 10px;
        }
    }

    @media (max-width: 900px) {
        > span:nth-child(2) {
            width       : unset;
            margin-left : 15px;
            margin-right: 15px;
        }
    }

    > div {
        display          : flex;
        flex-direction   : column;
        -webkit-box-align: center;
        align-items      : center;
        width            : 100%;
        margin-top       : 40px;
        @media (max-width: 820px) {
            .faq-card:last-of-type {
                margin-bottom: 0px;
            }
        }
    }
}
@media (max-width: 820px) {
    .faq-container {
        height: auto;
    }
}
.faq-card {
    display          : flex;
    -webkit-box-align: center;
    align-items      : center;
    width            : calc(100% - 30px);
    min-height       : 64px;
    max-width        : 600px;
    cursor           : pointer;
    user-select      : none;
    transition       : border 0.2s ease 0s;
    margin-left      : 15px;
    margin-right     : 15px;
    background       : linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
    box-shadow       : rgba(0, 0, 0, .15%) 0px 5px 10px;
    border-radius    : 20px;
    border           : 2px solid #39483b;

    > div {
        display       : flex;
        flex-direction: column;
        width         : 100%;

        > div:nth-child(1) {
            margin-top       : 20px;
            margin-bottom    : 20px;
            display          : flex;
            -webkit-box-align: center;
            align-items      : center;

            > span {
                font-weight: bold;
                margin-left: 20px;
            }

            @media (max-width: 768px) {
                > span {
                    font-size: 16px;
                }
            }

            > img {
                transition  : all 0.2s ease 0s;
                margin-left : auto;
                margin-right: 10px;
                min-height  : 14px;
                min-width   : 14px;
            }

            > img.active {
                -webkit-transition: 3s;
                -moz-transition   : 3s;
                -ms-transition    : 3s;
                -o-transition     : 3s;
                transition        : 0.2s;
                transform         : rotate(45deg) !important;
            }
        }

        > div:nth-child(2) {
            margin-top       : 20px;
            display          : flex;
            -webkit-box-align: center;
            align-items      : center;
            height           : 1px;
            margin           : 0px 20px 10px;
            background       : white;
            opacity          : 0.1;
        }

        > span {
            font-size    : 14px;
            font-weight  : 400;
            margin-left  : 20px;
            margin-right : 20px;
            margin-bottom: 12px;
            opacity      : 0.7;
            z-index      : 999;
        }
    }
}

.faq-card.active {
    -webkit-transition: 3s;
    -moz-transition   : 3s;
    -ms-transition    : 3s;
    -o-transition     : 3s;
    transition        : 0.2s;
    border-color      : #a8ce8c !important;
}

.faq-card:not(:first-of-type) {
    margin-top: 20px;
}