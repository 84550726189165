@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Kavoon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Snippet&display=swap');

html,
body {
  margin: 0;
}

body * {
  color      : aliceblue;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

#root {
  overflow-x: hidden;
}

input,
button {
  outline: none;
  border : none;
}

button {
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
}

.h1 {
  font-size: 60px;
}

.h2 {
  font-size: 54px;
}

.h3 {
  font-size: 50px;
}

.h4 {
  font-size: 30px;
}

.h5 {
  font-size: 20px;
}

.h6 {
  font-size: 18px;
}

.h7 {
  font-size: 16px;
}

.h8 {
  font-size: 14px;
}

body::-webkit-scrollbar {
  width : 0;
  height: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}