.footer-container {
    display          : flex;
    flex-direction   : column;
    -webkit-box-align: center;
    align-items      : center;
    position         : relative;
    height           : auto;
    background       : #8c453f;

    > .footer-badge {
        position         : absolute;
        -webkit-box-align: center;
        align-items      : center;
        top              : -125px;
        display          : flex;
        background       : linear-gradient(#f2bc8d 0%, #d87319 99.79%);
        box-shadow       : rgba(0, 0, 0, .15) 0px 20px 40px;
        border-radius    : 40px;
        height           : 250px;
        max-width        : 1240px;
        width            : calc(100% - 30px);
        user-select      : none;

        > div {
            display       : flex;
            flex-direction: column;
        }

        > .footer-badge-img {
            display       : flex;
            flex-direction: column;
            background    : rgba(0, 0, 0, 0.8);
            border-radius : 25px;
            width         : 280px;
            height        : 190px;
            margin-left   : 30px;
            margin-right  : 25px;
            overflow      : hidden;

            > img {
                width: 100%;
            }
        }

        @media (max-width:1070px) {
            > .footer-badge-img {
                -webkit-box-align: center;
                align-items      : center;
                width            : 240px;
                height           : 160px;
                margin-left      : 25px;
                margin-top       : 20px;
            }
        }

        > .footer-ready-lorem {
            > span:nth-child(1) {
                font-weight: bold;
            }

            @media (max-width:1070px) {
                > span:nth-child(1) {
                    font-size : 30px;
                    margin-top: 5px;
                }
            }

            > span:nth-child(2) {
                font-weight: 600;
            }

            @media (max-width:1070px) {
                > span:nth-child(2) {
                    text-align : center;
                    font-size  : 20px;
                    width      : 250px;
                    line-height: 24px;
                }
            }
        }

        @media (max-width:1070px) {
            > .footer-ready-lorem {
                -webkit-box-align: center;
                align-items      : center;
            }
        }

        > .footer-start-btn {
            width            : 200px;
            height           : 60px;
            display          : flex;
            -webkit-box-align: center;
            align-items      : center;
            cursor           : pointer;
            -webkit-box-pack : center;
            justify-content  : center;
            border-radius    : 30px;
            background       : white;
            margin-left      : auto;
            margin-right     : 30px;
            transition       : transform 0.1s ease 0s;

            > span {
                color      : rgb(41, 192, 107);
                font-family: Poppins;
                font-weight: bold;
                font-size  : 18px;
            }

            > img {
                margin-top : 3px;
                margin-left: 5px;
            }
        }

        > .footer-start-btn:hover {
            transform: scale(1.02);
        }

        @media (max-width:1070px) {
            > .footer-start-btn {
                margin-left : unset;
                margin-right: unset;
                margin-top  : 20px;
                width       : 250px;
            }
        }
    }

    @media (max-width:1070px) {
        > .footer-badge {
            flex-direction: column;
            height        : 375px;
        }
    }

    > .footer-last {
        display          : flex;
        user-select      : none;
        margin       : 50px 0;
        max-width        : 1240px;
        width            : calc(100% - 30px);
        -webkit-box-pack : justify;
        justify-content  : space-between;
        -webkit-box-align: center;
        align-items      : center;

        > span, div {
            margin-top: 20px;
            text-align: center;
        }

        @media (max-width:768px) {
            > span {
                margin-top: 15px;
                order     : 3;
            }
        }

        @media (max-width:768px) {
            > div {
                margin-top: 30px;
                order     : 2;
            }
        }

        img + img {
            margin-left: 30px;
        }
    }

    @media (max-width:768px) {
        > .footer-last {
            flex-direction: column;
        }
    }

    @media (max-width:1070px) {
        > .footer-last {
            margin-top: 280px;
        }
    }
}

@media (max-width:768px) {
    .footer-container {
        height: 461px;
    }
}