.providers-container {
    display       : flex;
    flex-direction: column;
    background    : #044e83;
    height        : 855px;

    > .providers-wrapper {
        height           : 100%;
        display          : flex;
        -webkit-box-pack : justify;
        justify-content  : space-between;
        -webkit-box-align: center;
        align-items      : center;
        max-width        : 1240px;
        margin           : 0px auto;

        > .providers-innovative {
            display       : flex;
            width         : 100%;
            height        : 555px;
            flex-direction: column;
            align-items   : flex-end;
            padding-right : 80px;

            > span:nth-child(1) {
                font-size              : 48px;
                user-select            : none;
                font-weight            : bold;
                width                  : 570px;
                background             : -webkit-linear-gradient(#f2f2f2 0%, #d87319 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            @media (max-width: 768px) {
                > span:nth-child(1) {
                    width       : unset;
                    margin-left : 15px;
                    margin-right: 15px;
                    font-size   : 30px;
                }
            }

            @media (max-width: 1220px) {
                > span:nth-child(1) {
                    width: auto;
                }
            }

            > span:nth-child(2) {
                width     : 570px;
                margin-top: 25px;
                color     : rgba(255, 255, 255, 0.7);

                > * {
                    color: rgba(255, 255, 255, 0.7);
                }

                > span {
                    display   : flex;
                    margin-top: 15px;
                }

                > .providers-list {
                    margin-top: 10px !important;
                }
            }

            @media (max-width: 768px) {
                > span:nth-child(2) {
                    margin-left : 15px;
                    margin-right: 15px;
                    margin-top  : 10px;
                    font-size   : 16px;
                }
            }

            @media (max-width: 1220px) {
                > span:nth-child(2) {
                    width: unset;
                }
            }
        }

        @media (max-width: 1220px) {
            > .providers-innovative {
                height           : auto;
                padding-right    : 0px;
                -webkit-box-align: center;
                align-items      : center;
                max-width        : 500px;
            }
        }

        > .providers-separator {
            min-width : 1px;
            height    : 555px;
            background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 3.68%, rgba(255, 255, 255, 0.1) 96.4%, rgba(255, 255, 255, 0) 100%);
        }

        @media (max-width: 768px) {
            > .providers-separator {
                width  : calc(100% - 30px);
                height : 1px;
                display: block;
                margin : 15px 15px 30px !important;
            }
        }

        @media (max-width: 1220px) {
            > .providers-separator {
                width        : 555px;
                height       : 1px;
                margin-top   : 15px;
                margin-bottom: 15px;
            }
        }

        > .providers-content {
            display         : grid;
            row-gap         : 75px;
            -webkit-box-pack: start;
            justify-content : flex-start;
            padding-left    : 80px;
            width           : 100%;
            height          : 750px;
            flex-direction  : column;

            > div {
                display         : flex;
                flex-direction  : row-reverse;
                -webkit-box-pack: justify;
                justify-content : space-between;
                height          : 70px;

                > .providers-icon {
                    display          : flex;
                    -webkit-box-pack : center;
                    justify-content  : center;
                    -webkit-box-align: center;
                    align-items      : center;
                    padding          : 10px;
                    margin-left      : 100px;
                    box-sizing       : border-box;
                    width            : 70px;
                    height           : 70px;
                    border-radius    : 15px;
                    background       : linear-gradient(rgba(255, 255, 255, 0.25) -0.21%, rgba(255, 255, 255, 0.1) 99.67%);
                }

                @media (min-width: 1120px) and (max-width: 1400px) {
                    > .providers-icon {
                        margin-left: 20px;
                    }
                }

                @media (max-width: 768px) {
                    > .providers-icon {
                        margin-left  : 0px;
                        align-self   : center;
                        margin-bottom: 10px;
                    }
                }

                @media (max-width: 1220px) {
                    > .providers-icon {
                        width     : 50px;
                        height    : 50px;
                        align-self: center;
                        margin-top: 10px;

                        > img {
                            width: -webkit-fill-available;
                        }
                    }
                }

                > div:nth-child(2) .h5 {
                    font-weight: bold;
                }

                > div:nth-child(2) .providers-description {
                    opacity   : 0.7;
                    width     : 367px;
                    margin-top: 5px;
                }
            }

            @media (max-width: 767px) {
                > div {
                    flex-direction: column;
                    text-align    : center;
                }
            }
        }

        @media (max-width: 1220px) {
            > .providers-content {
                padding-left    : 0px;
                -webkit-box-pack: center;
                justify-content : center;
            }
        }

        @media (max-width: 767px) {
            > .providers-content {
                height: 800px;
            }
        }
    }

    @media (max-width: 1220px) {
        > .providers-wrapper {
            flex-direction: column;
            margin-top    : 50px;
            margin-bottom : 50px;
            width         : 100%;
        }
    }

    @media (max-width: 768px) {
        > .providers-wrapper {
            -webkit-box-pack: unset;
            justify-content : unset;
        }
    }
}

@media (max-width: 1220px) {
    .providers-container {
        height: 100%;
    }
}