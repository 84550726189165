.roadmap-container {
    margin: 120px 0px;

    > span:nth-child(1) {
        font-weight: bold;
    }

    > span:nth-child(2) {
        opacity    : 0.7;
        font-weight: 500;
        text-align : center;
        font-size  : 20px;
    }

    @media (max-width:768px) {
        > span:nth-child(2) {
            padding: 0px 10px;
        }
    }

    > .roadmap-grid {
        display              : grid;
        grid-template-columns: repeat(4, 1fr);
        gap                  : 30px;
        width                : 100%;
        margin-top           : 40px;
        -webkit-box-pack     : center;
        justify-content      : center;
        max-width            : 1220px;

        > .roadmap-item {
            display        : flex;
            flex-direction : column;
            width          : 287px;
            // height         : 221px;
            margin         : 30px 0px;
            background     : linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
            box-shadow     : rgba(0, 0, 0, 0.2) 0px 10px 20px;
            backdrop-filter: blur(27.1828px);
            border-radius  : 25px;

            > div {
                display    : flex;
                margin-left: 20px;
                margin-top : 20px;

                > .roadmap-icon {
                    display          : flex;
                    -webkit-box-pack : center;
                    justify-content  : center;
                    -webkit-box-align: center;
                    align-items      : center;
                    width            : 58px;
                    height           : 58px;
                    background       : linear-gradient(#f2f2f2 0%, #d87319 100%);
                    border-radius    : 20px;
                }

                > .roadmap-detail {
                    user-select   : none;
                    display       : flex;
                    flex-direction: column;
                    margin-left   : 10px;

                    > .h5 {
                        font-size  : 20px;
                        font-weight: bold;
                    }
                }
            }

            > span:nth-of-type(1) {
                margin-top  : 8px;
                margin-left : 20px;
                margin-right: 20px;
                margin-bottom: 20px;
                font-weight : 500;
                opacity     : 0.7;
            }
        }

        @media (max-width:768px) {
            > .roadmap-item {
                margin: 0px;
            }
        }
    }

    @media (max-width:1260px) {
        > .roadmap-grid {
            grid-template-columns: repeat(2, 1fr);
            max-width            : 768px;
            place-items          : center;
            -webkit-box-align    : center;
        }
    }

    @media (max-width:768px) {
        > .roadmap-grid {
            grid-template-columns: 1fr;
            gap                  : 30px;
        }
    }
}
@media screen and (max-width: 1400px) {
    .roadmap-container {
        margin-top: unset !important;
    }
}