.header-container {
    position       : relative;
    padding-left   : 50px;
    padding-right  : 50px;
    padding-top    : 20px;
    display        : flex;
    z-index        : 2;
    height         : 70px;
    justify-content: space-between;

    > .header-logo {
        display    : flex;
        align-items: center;
    }

    > .header-links {
        display              : grid;
        grid-template-columns: repeat(5, min-content);
        column-gap           : 50px;
        align-items          : center;

        > a {
            font-weight: 600;
        }
    }

    > .header-openapp {
        display    : flex;
        align-items: center;

        > .header-pink-btn {
            width           : 180px;
            height          : 60px;
            background-color: rgb(227, 122, 255);
            cursor          : pointer;
            border-radius   : 30px;
            font-weight     : 700;
        }
    }
}

.header-mobile-container {
    position         : relative;
    z-index          : 2;
    display          : flex;
    -webkit-box-align: center;
    align-items      : center;
    height           : 70px;
    background       : rgba(255, 255, 255, 0.1);
    backdrop-filter  : blur(27.1828px);
    -webkit-box-pack : justify;
    justify-content  : space-between;

    div {
        display          : flex;
        -webkit-box-align: center;
        align-items      : center;
        -webkit-box-pack : center;
        justify-content  : center;
        cursor           : pointer;
        width            : 40px;
        height           : 40px;
        background       : rgba(255, 255, 255, 0.15);
        border-radius    : 10px;
    }

    > div:first-of-type {
        margin-left: 10px;
    }

    > div:last-of-type {
        margin-right: 10px;
    }

    > a {
        margin: 0px 10px;
    }
}

.header-mobile-menu {
    width          : 100vw;
    position       : absolute;
    top            : 70px;
    z-index        : 3;
    width          : 100% !important;
    background     : rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(81.5485px);
    opacity        : 0;
    display        : flex;
    flex-direction : column;

    > a {
        opacity          : 0;
        transform        : translateY(25px) translateZ(0px);
        transition       : 0.2s;
        display          : flex;
        -webkit-box-pack : center;
        justify-content  : center;
        -webkit-box-align: center;
        align-items      : center;
        color            : inherit;
        text-decoration  : none;
        height           : 60px;
        margin-left      : 20px;
        margin-right     : 20px;
        margin-bottom    : 20px;
        font-weight      : 600;
        background       : rgba(255, 255, 255, 0.12);
        border-radius    : 0px;
        user-select      : none;
        cursor           : unset;
    }

    > a:first-child {
        margin-top: 20px;
    }
}

.header-mobile-menu.active {
    -webkit-transition: .2s;
    -moz-transition   : .2s;
    -ms-transition    : .2s;
    -o-transition     : .2s;
    height            : 100vh;
    width             : 100vw;
    opacity           : 1;

    > a {
        opacity  : 1;
        cursor   : pointer;
        transform: translateY(0px) translateZ(0px);
    }
}