.root {
    display       : flex;
    flex-direction: column;

    > .godfather-wrapper {
        position        : relative;
        background-image: url('../../assets/image/background.png');
        background-size : cover;
    }

    > .godfather-wrapper::before {
        content   : '';
        background: radial-gradient(99.4% 99.4% at 50% 0%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%);
        display   : block;
        position  : absolute;
        width     : 100%;
        height    : 100%;
    }

    > .token-road-wrapper {
        background: #667633;
        position  : relative;

        > * {
            position: relative;
            z-index : 4;
        }

        > div {
            display          : flex;
            flex-direction   : column;
            -webkit-box-align: center;
            align-items      : center;
        }

        > .rocket-img {
            position  : absolute;
            bottom    : 0px;
            left      : 0px;
            right     : 0px;
            z-index   : 0;
            width     : 100%;
            height    : auto;
            max-height: 1200px;
            opacity   : 0.7;
        }
        @media (max-width:768px) {
            > .rocket-img {
                // left      : -28px;
            }
        }
    }
}