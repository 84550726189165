.welcome-container {
    position      : relative;
    display       : flex;
    justify-content: center;
    // flex-direction: column;
    height        : 624px;
    z-index       : 2;

    > .welcome-first {
        display          : flex;
        flex-direction   : column;
        margin-top       : 100px;
        -webkit-box-align: center;
        align-items      : center;

        > span:nth-child(1) {
            max-width  : 600px;
            font-weight: 700;
            text-align : center;
            user-select: none;

            > span {
                font-weight: 800;
            }
        }

        @media screen and (max-width: 768px) {
            > span:nth-child(1) {
                margin-left : 15px;
                margin-right: 15px;
                max-width   : 300px;
                font-size   : 40px;
                font-weight : 600;
            }
        }

        > span:nth-child(2) {
            font-weight: 500;
            opacity    : 0.7;
            margin-top : 15px;
            max-width  : 800px;
            text-align : center;
            user-select: none;
        }

        @media (max-width: 768px) {
            > span:nth-child(2) {
                max-width   : 300px;
                margin-left : 15px;
                margin-right: 15px;
            }
        }

        > .welcome-actions {
            display    : flex;
            margin-top : 20px;
            user-select: none;

            > button:nth-child(1) {
                width         : 180px;
                height        : 60px;
                background    : rgb(78, 198, 118);
                border-radius : 30px;
                color         : white;
                font-weight   : bold;
                letter-spacing: 0.4px;
                cursor        : pointer;
            }

            > button:nth-child(1):hover {
                background: rgb(45, 213, 119);
            }

            > button:nth-child(2) {
                width         : 180px;
                height        : 60px;
                background    : rgb(47, 145, 238);
                border-radius : 30px;
                color         : white;
                font-weight   : bold;
                letter-spacing: 0.4px;
                cursor        : pointer;
            }

            > button:nth-child(2):hover {
                background: rgb(78, 160, 237);
            }

            > button+button {
                margin-left: 20px;
            }
        }

        @media (max-width: 768px) {
            > .welcome-actions {
                flex-direction   : column;
                -webkit-box-align: center;
                align-items      : center;
                width            : 100%;

                > button {
                    margin-left : 15px;
                    margin-right: 15px;
                    width       : calc(100% - 30px) !important;
                    max-width   : 290px;
                }

                > button:nth-of-type(2) {
                    margin-left: 15px;
                    margin-top : 10px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        > .welcome-first {
            margin-top: 70px;
        }
    }

    > .welcome-second {
        transition       : all 0.3s ease 0s;
        display          : flex;
        // background       : rgba(255, 255, 255, 0.1);
        // backdrop-filter  : blur(81.5485px);
        border-radius    : 30px 30px 0px 0px;
        width            : 50%;
        height           : auto;
        max-width        : 540px;
        margin-left      : 100px;
        margin-right     : 100px;
        margin-top       : 60px;
        -webkit-box-align: center;
        align-items      : center;
        align-self       : center;
        -webkit-box-pack : center;
        justify-content  : center;
        -webkit-user-drag: none;
        user-select      : none;
        overflow         : hidden;

        > img {
            width            : 100%;
            height           : 100%;
            pointer-events   : none;
            -webkit-user-drag: none;
        }
    }

    @media screen and (max-width: 1220px) {
        > .welcome-second {
            height       : auto;
            width        : 550px;
            margin       : 50px;
            border-radius: 15px;
            display: none;
        }
    }

    @media screen and (max-width: 768px) {
        > .welcome-second {
            height      : 290px;
            max-width   : 550px;
            width       : calc(100% - 30px);
            margin-left : 15px;
            margin-right: 15px;
        }
    }
}

@media screen and (max-width: 1220px) {
    > .welcome-container {
        height           : 812px;
        -webkit-box-align: center;
        align-items      : center;
        -webkit-box-pack : center;
        justify-content  : center;
    }
}
@media screen and (max-width: 1220px) {
    .welcome-container {
        height: auto;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
    }

    .welcome-first {
        margin-top: 0px;
        margin-bottom: 100px;
    }
}