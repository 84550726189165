.token-container {
    > .token-wrapper1 {
        display         : flex;
        margin-right    : 100px;
        margin-left     : 35px;
        flex-direction: row;
        -webkit-box-pack: justify;
        justify-content : space-between;
        align-items     : center;
        max-width       : 1440px;
        z-index         : 2;

        > div:first-of-type {
            justify-content: flex-end;
            display:flex;
            width: inherit;

            > img {
                flex:1;
                margin-top: 35px;
                width:445px;
                height:683px;
            }
            @media (max-width:768px) {
                > img {
                    margin-top:12px;
                }
            }
            @media (max-width:1400px) {
                > img {
                    margin-top:unset;
                }
            }
        }
        @media (max-width:700px) {
            > div:first-of-type {
                justify-content: unset;
            }
        }

        > div:last-of-type {
            display       : flex;
            flex-direction: column;

            > .h2 {
                font-weight: bold;
                width      : 620px;
            }
            @media (max-width:768px) {
                > .h2 {
                    font-size: 30px;
                }
            }
            @media (max-width: 1400px) {
                > .h2 {
                    width: unset;
                    text-align: center;
                }
            }
            > span:nth-child(2) {
                display       : flex;
                flex-direction: column;
                font-weight   : 500;
                opacity       : 0.7;
                width         : 541px;

                > span {
                    margin-top: 25px;
                }
            }
            @media (max-width: 1400px) {
                > span:nth-child(2) {
                    width: unset;
                    text-align: center;
                }
            }
            @media (max-width: 768px) {
                > span:nth-child(2) {
                    font-size: 16px;
                    margin-top: 10px;
                    padding: 0px 10px;
                }
            }
        }
        @media (max-width:768px) {
            > div:last-of-type {
                margin-top:unset;
            }
        }
        @media (max-width:1400px) {
            > div:last-of-type {
                margin-left:30px;
                margin-right:30px;
            }
        }
    }
    @media (max-width:1400px) {
        > .token-wrapper1 {
            -webkit-box-align: center;
            align-items: center;
            flex-direction: column-reverse;
            margin-left: 50px;
            margin-right: 50px;
            margin-top      : 100px;
        }
    }
    @media (max-width:768px) {
        > .token-wrapper1 {
            margin-top:50px;
        }
    }
}

.diagram-container {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    max-width: 1440px;
    z-index: 2;
    > div {
        width:667px;
        height:578px;
        position: relative;
        > div {
            position:absolute;
            border-radius:100%;
            transform-origin: center;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 31px;
            font-weight: bold;
            > .diagram-content {
                font-size: 13px;
                font-weight: 300;
            }
        }
        > div:nth-child(1) {
            top:28%;
            left:27%;
            background-color: rgba(47, 145, 238,.9);
            box-shadow:1px 1px 13px 1px rgb(47, 145, 238);
            width:200px;
            height:200px;
        }
        > div:nth-child(2) {
            top:33%;
            right:8%;
            background-color: rgba(45, 213, 119,.9);
            box-shadow:1px 1px 13px 1px rgb(45, 213, 119);
            width:227px;
            height:227px;
        }
        > div:nth-child(3) {
            top:62%;
            left:7%;
            background-color: rgba(244, 40, 73,.9);
            box-shadow:1px 1px 13px 1px rgb(244, 40, 73);
            width:180px;
            height:180px;
        }
        > div:nth-child(4) {
            top:66.5%;
            left:39.5%;
            background-color: rgba(202, 53, 242,.9);
            box-shadow:1px 1px 13px 1px rgb(202, 53, 242);
            width:130px;
            height:130px;
        }
    }
}
@media (max-width: 1400px) {
    .diagram-container {
        flex:1;
        -webkit-box-align: center;
        align-items: center;
        flex-direction: column-reverse;
        margin-left: 50px;
        margin-right: 50px;
    }
}

.small-diagram-container {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: center;
    width:100%;
    z-index: 2;
    > div {
        width:290px;
        height:578px;
        position: relative;
        > div {
            position:absolute;
            border-radius:100%;
            transform-origin: center;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 30px;
            font-weight: bold;
            > .small-diagram-content {
                font-size: 13px;
                font-weight: 350;
            }
        }
        > div:nth-child(1) {
            top:8%;
            left:0%;
            background-color: rgba(47, 145, 238,.9);
            box-shadow:1px 1px 50px 1px rgba(47, 145, 238,.3);
            width:290px;
            height:290px;
        }
        > div:nth-child(2) {
            top:50%;
            left:0%;
            background-color: rgba(45, 213, 119,.9);
            box-shadow:1px 1px 50px 1px rgba(45, 213, 119,.3);
            width:227px;
            height: 227px;
        }
        > div:nth-child(3) {
            top:44%;
            right:0%;
            background-color: rgba(244, 40, 73,.9);
            box-shadow:1px 1px 50px 1px rgba(244, 40, 73,.3);
            width:140px;
            height:140px;
        }
        > div:nth-child(4) {
            top:76%;
            right:4%;
            background-color: rgba(202, 53, 242,.9);
            box-shadow:1px 1px 50px 1px rgba(202, 53, 242,.3);
            width:130px;
            height:130px;
        }
    }
}